import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from '@mui/material/Toolbar';
import Loader from "../Utility/Loader";
import { useGetVideoFiles } from "../../hooks/useFiles";

export default function VideoFiles() {
  const { data, loading, getVideoFiles } = useGetVideoFiles();
  React.useEffect(() => {
    getVideoFiles();
  }, []);
  if (loading) {
    return <Loader />;
  }

function EnhancedTableToolbar() {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
   <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Biomarker Reports For Journal Video
        </Typography>
    </Toolbar>
  );
}
  return (
    <Box sx={{ width: '100%' }}>
    <EnhancedTableToolbar/>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>File Id</TableCell>
            <TableCell>Member Id</TableCell>
            <TableCell>Consultation</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>File</TableCell>
            <TableCell>Biomarker Report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.memberId}</TableCell>
              <TableCell>{row.consultationId}</TableCell>
              <TableCell>{new Date(row.createdAt).toJSON().slice(0,10).split('-').reverse().join('/')}</TableCell>
              <TableCell>
                <a href={row.fileUrl}>View</a>
              </TableCell>
              <TableCell>
                Pending
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}
