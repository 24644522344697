import client from "../config/axiosConfig";

export const loginApi = (payload) => client.post("/user/login", payload);

export const registerApi = (payload) => client.post("/user/register/admin", payload);

export const refreshApi = (payload) => client.post("/user/refresh", payload);

export const resetApi = (payload) => client.post("/user/reset", payload);
export const getChatAuth = () => client.get("/chat/token");
