import React from "react";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import VideoFiles from "../VideoFiles"
export default function SignUp() {
  return (
    <Grid
      sx={{  padding: 18}}
      container
      flexWrap="nowrap"
    >
      <Grid  item sx={{ flexGrow: 1 }} >
        <Paper variant="outlined" sx={{ height: "100%" }}>
          <VideoFiles />
        </Paper>
      </Grid>
    </Grid>
  );
}
