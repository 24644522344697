import React, { useContext, useEffect } from "react";
import {
  BrowserRouter,
  useLocation,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useScreenDetector } from "../hooks/useScreenDetector";

import Login from "../components/Login";
import Register from "../components/Register";
import Dashboard from "../components/Dashboard";
import Account from "../components/Account";

import PublicLayout from "../components/Layouts/Web/Public";
import PrivateLayout from "../components/Layouts/Web/Private";

import MobilePublicLayout from "../components/Layouts/Mobile/Public";
import MobileDashboardLayout from "../components/Layouts/Mobile/Dashboard";
import { useStartup } from "../hooks/useStartup";

function PrivateRoute({ children }) {
  const { auth } = useContext(AuthContext);

  const location = useLocation();
 
  if (auth === null) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}
function PublicRoute({ children }) {
  const { auth } = useContext(AuthContext);

  if (auth !== null) {
    return <Navigate to="/dashboard" />;
  }
  return children;
}

function App() {
  useStartup();

  const { isDesktop } = useScreenDetector();
  if (isDesktop) {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <PublicLayout>
                  <Login />
                </PublicLayout>
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <PublicLayout>
                  <Register />
                </PublicLayout>
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard />
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Account />
                </PrivateLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <MobilePublicLayout>
                  <Login />
                </MobilePublicLayout>
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <MobilePublicLayout>
                  <Register />
                </MobilePublicLayout>
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <MobileDashboardLayout>
                  <Dashboard />
                </MobileDashboardLayout>
              </PrivateRoute>
            }
          />
       
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <MobileDashboardLayout>
                  <Account />
                </MobileDashboardLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
